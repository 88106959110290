import { IStringUtilities } from "@/Core/Utilities/IStringUtilities";

export default class Program {
    private stringUtilities: IStringUtilities;
    private domElement: HTMLElement | null;

    constructor(stringUtilities: IStringUtilities, domElement: HTMLElement | null) {
        this.stringUtilities = stringUtilities;
        this.domElement = domElement;
    }

    public start() {
        import(`@/Versions/${this.getApplicableVersion()}/index`)
            .then((module) => module.default())
            .catch(() => import(`@default/index`).then((module) => module.default()).catch((error) => console.log(error)));
    }

    private getApplicableVersion() {
        const applicableVersion = window.__APP__.version;
        if (!applicableVersion) {
            return "Default";
        }
        return this.stringUtilities.toPascalCase(applicableVersion);
    }
}
